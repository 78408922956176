import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import Cookies from '../components/Cookies';
import Layout from '../components/Layout';
import Collapsible from '../components/UI/Collapsible';
import ContentContainer from '../components/UI/ContentContainer';
import PageContentOverlap from '../components/UI/PageContentOverlap';
import PageHeader from '../components/UI/PageHeader';
import { breakpointLarge } from '../styles/breakpoints';

  const ContainerSection = styled.div`
 
  background-color: #fff;
  padding:1rem;
  border-radius:2.5rem;
  @media (min-width: ${breakpointLarge}) {
    padding:1.5rem;
  }
  `

  const Table = styled.table`
    width: 100%;
    border-collapse: collapse;
  `;

  const TableRow = styled.tr`
    background-color: ${props => (props.isFirstRow ? '#9b59b6' : 'transparent')};
    color: ${props => (props.isFirstRow ? '#fff' : '#000')};
  `;

  const TableCell = styled.td`
    padding: 8px;
    border: 1px solid #ddd;
  `;
  

 

export const FaqPageTemplate = ({
  image,
  imagePosition,
  title,
  description,
  items,
}) => {
  return (
    <section>
      <Cookies/>
      <PageHeader
        image={image}
        backgroundPosition={imagePosition}
        title={title}
        description={description}
      />
      <PageContentOverlap>
          <ContentContainer>
          <ContainerSection>
          {items.map(item => (
            <Collapsible title={item.title} key={item.title}>
              <p>{item.description} </p>
              {item.title ===
                '¿Cómo pagar las cuotas?' && (
                  <span style={{ color: 'red' }}>
                    *Pagos por canales distintos de PSE se pueden demorar hasta 3 días hábiles para aplicarse, puedes seguir recibiendo mensajes de mora.
                  </span>
                )}
              {item.title === '¿Por qué el desembolso es menor al valor del crédito solicitado?' && (
                  <Table>
                      <tbody>
                      <TableRow isFirstRow> 
                        <TableCell>SERVICIO INCLUIDO</TableCell>
                        <TableCell>VALOR</TableCell>
                        <TableCell>TIEMPO DE COBRO</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Garantía mobiliaria</TableCell>
                        <TableCell>$79.730</TableCell>
                        <TableCell>Desembolso</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval fijo</TableCell>
                        <TableCell>10%</TableCell>
                        <TableCell>Desembolso</TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                )}

              {item.title === 'Aval, fianza o codeudor para garantizar mi crédito' && (
                  <Table>
                      <tbody>
                      <TableRow isFirstRow> 
                        <TableCell>SERVICIO INCLUIDO</TableCell>
                        <TableCell>VALOR</TableCell>
                        <TableCell>TIEMPO DE COBRO</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval Variable Mujeres</TableCell>
                        <TableCell>2.75%</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval Variable Hombres</TableCell>
                        <TableCell>3.60%</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval fijo</TableCell>
                        <TableCell>10%</TableCell>
                        <TableCell>Desembolso</TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                )}
                {item.title === '¿Qué incluye mi cuota?' && (
                  <Table>
                      <tbody>
                      <TableRow isFirstRow> 
                        <TableCell>SERVICIO INCLUIDO</TableCell>
                        <TableCell>VALOR</TableCell>
                        <TableCell>TIEMPO DE COBRO</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Firma electrónica 12 meses</TableCell>
                        <TableCell>$27.750</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Firma electrónica 18 meses</TableCell>
                        <TableCell>$18.500</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Firma electrónica 24 meses</TableCell>
                        <TableCell>$13.875</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval Variable Mujeres</TableCell>
                        <TableCell>2.75%</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell>Aval Variable Hombres</TableCell>
                        <TableCell>3.60%</TableCell>
                        <TableCell>Mensual</TableCell>
                      </TableRow>
                    </tbody>
                  </Table>
                )}
            </Collapsible>
          ))}
          </ContainerSection>
          </ContentContainer>
        
      </PageContentOverlap>
    </section>
  );
};

FaqPageTemplate.propTypes = {
  image: PropTypes.object,
  imagePosition: PropTypes.number,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
    })
  ),
};

const FaqPage = ({ data }) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <FaqPageTemplate
        image={post.frontmatter.image}
        imagePosition={post.frontmatter.imagePosition}
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        items={post.frontmatter.items}
      />
    </Layout>
  );
};

FaqPage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default FaqPage;

export const faqPageQuery = graphql`
  query FaqPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 1600, quality: 90) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        imagePosition
        title
        description
        items {
          title
          description
        }
      }
    }
  }
`;
